import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.4_562alviiunmwx6wnq4rxaxlewq/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.4_562alviiunmwx6wnq4rxaxlewq/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.4_562alviiunmwx6wnq4rxaxlewq/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._r6zh3fe7ad6s2nkaggrqzasgo4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Check/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/pwainstall.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/shortcutinstall.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/nav.tsx");
